<template>
    <SelectTimeModal id="select-time-modal" />
    <AccountActivatedModal />
    <PasswordResetSuccessfulModal />
    <button
        class="d-none"
        data-bs-toggle="modal"
        data-bs-target="#accountActivatedModal"
        ref="toggleAccountActivatedModal"
    ></button>
    <button
        class="d-none"
        data-bs-toggle="modal"
        data-bs-target="#passwordResetSuccessfulModal"
        ref="togglePasswordResetSuccessfulModal"
    ></button>
    <div
        :style="{
            'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
            'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM,
        }"
        style="z-index: 3"
    >
        <div class="container">
            <h1 class="mb-3 text-light">
                {{ welcomeMessage }}
            </h1>
            <div class="card shadow-sm">
                <h5 class="mb-3 p-3 pb-0">
                    {{ t('pages.home.yourNextBooking') }}
                </h5>
                <div class="">
                    <div
                        v-if="!activeBookings || !activeBookings.length"
                        class="text-muted px-3 pb-3"
                    >
                        {{ t('pages.bookings.noActive') }}
                    </div>
                    <div
                        class="p-3 tap-react border-top border-bottom mb-3"
                        v-else
                    >
                        <router-link
                            style="color: inherit !important"
                            :to="'/bookings/' + activeBookings[0].booking.id"
                            class="d-flex align-items-center justify-content-between"
                        >
                            <div class="">
                                <h6 class="m-0" style="font-weight: 500">
                                    <span v-if="yachts">
                                        <span
                                            v-if="
                                                getYacht(
                                                    activeBookings[0].booking
                                                )
                                            "
                                        >
                                            <span
                                                v-if="
                                                    getYacht(
                                                        activeBookings[0]
                                                            .booking
                                                    ).brand
                                                "
                                            >
                                                {{
                                                    getYacht(
                                                        activeBookings[0]
                                                            .booking
                                                    ).brand.name
                                                }}
                                            </span>
                                            {{
                                                getYacht(
                                                    activeBookings[0].booking
                                                ).identity.name
                                            }}
                                        </span>
                                    </span>
                                    <span v-if="activeBookings[0].bookingType">
                                        —
                                        <span v-if="$i18n.locale == 'fr'">
                                            {{
                                                activeBookings[0].bookingType
                                                    .name_fr
                                            }}
                                        </span>
                                        <span v-else>
                                            {{
                                                activeBookings[0].bookingType
                                                    .name_en
                                            }}
                                        </span>
                                    </span>
                                </h6>
                                <p class="mt-2 mb-0">
                                    <span
                                        v-if="activeBookings[0]"
                                        class="badge lh-sm bg-secondary text-light"
                                        :class="
                                            diff(activeBookings[0].booking)
                                                .classes
                                        "
                                    >
                                        {{
                                            diff(activeBookings[0].booking)
                                                .status
                                        }}
                                        {{
                                            diff(activeBookings[0].booking).text
                                        }}
                                    </span>
                                </p>
                            </div>
                            <div
                                style="width: 90%"
                                class="d-flex flex-row justify-content-end"
                            >
                                <div
                                    class="d-flex flex-column text-muted align-items-center"
                                    style="gap: 1.3rem; z-index: 100"
                                >
                                    <i
                                        class="dot-icon fa fa-circle py-1"
                                        style="background: white"
                                    ></i>
                                    <i
                                        class="dot-icon fa fa-circle py-1 pb-2"
                                        style="background: white"
                                    ></i>
                                </div>

                                <div
                                    class="d-flex flex-column align-items-start"
                                    style="
                                        gap: 0.5rem;
                                        padding-left: 1rem;
                                        margin-left: -0.35rem;
                                        border-left: 2px solid #cbcbcb;
                                    "
                                >
                                    <div>
                                        {{
                                            moment
                                                .utc(
                                                    activeBookings[0].booking
                                                        .start_timestamp
                                                )
                                                .format('MMM Do HH:mm')
                                        }}
                                    </div>
                                    <div>
                                        {{
                                            moment
                                                .utc(
                                                    activeBookings[0].booking
                                                        .end_timestamp
                                                )
                                                .format('MMM Do HH:mm')
                                        }}
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <div class="px-3 pb-3">
                        <router-link
                            to="/bookings"
                            class="btn bg-dark text-light w-100"
                            >{{
                                t('pages.bookings.viewAllBookings')
                            }}</router-link
                        >
                    </div>
                </div>
            </div>
            <div
                @click="router.push('/more/my-subscription')"
                v-if="user.oneOfSubscriptionExpired"
                class="card mt-3 bg-danger rounded-pill border-0 px-3 py-2 bg-danger-hover"
                style="cursor: pointer"
            >
                <div
                    class="d-flex flex-row align-items-center text-white"
                    style="gap: 0.5rem"
                >
                    <i class="fa fa-triangle-exclamation text-white"></i>
                    <div>
                        <p
                            class="m-0"
                            style="line-height: 1.3rem; font-weight: 500"
                        >
                            {{ t('pages.home.subscriptionExpiredMessage') }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <h3 class="container mt-4 mb-3">{{ t('pages.home.yourYachts') }}</h3>
        <div
            v-if="subscribedYachts && subscribedYachts.length != 0"
            class="mb-5"
        >
            <Flicking
                :options="{
                    horizontal: true,
                    align: 'prev',
                    moveType: 'freeScroll',
                    resizeOnContentsReady: true,
                }"
                class="mb-4 container-sm"
            >
                <router-link
                    v-for="yacht in subscribedYachts"
                    :key="yacht"
                    :to="`/yachts/${yacht.identity.id}`"
                    class="me-3"
                >
                    <YachtItem :yacht="yacht" />
                </router-link>
            </Flicking>
        </div>
        <div class="container mb-5" v-else>
            {{ t('pages.home.notSubscribedToAny') }}
        </div>
        <h3 class="mb-3 container">{{ t('pages.home.featured') }}</h3>
        <div v-if="featuredYachts && featuredYachts.length != 0" class="mb-5">
            <Flicking
                :options="{
                    horizontal: true,
                    align: 'prev',
                    moveType: 'freeScroll',
                    resizeOnContentsReady: true,
                }"
                class="mb-4 container-sm"
            >
                <router-link
                    v-for="yacht in featuredYachts"
                    :key="yacht"
                    :to="`/yachts/${yacht.identity.id}`"
                    class="me-3"
                >
                    <YachtItem :yacht="yacht" />
                </router-link>
            </Flicking>
        </div>
        <div class="mb-5 container" v-else>
            {{ t('pages.home.noYachtsInCollection') }}
        </div>
        <div class="mb-5" v-if="articles && articles.length != 0">
            <h3 class="mb-3 container">{{ t('pages.home.news') }}</h3>
            <Flicking
                :options="{
                    horizontal: true,
                    align: 'prev',
                    moveType: 'freeScroll',
                    resizeOnContentsReady: true,
                }"
                class="mb-4 container-sm"
            >
                <span v-for="article in articles" :key="article.id">
                    <div
                        class="me-3"
                        v-if="
                            article.status == 'published' &&
                            article.is_link == false
                        "
                        @click="goToArticle(article.id)"
                        style="color: inherit !important"
                    >
                        <ArticleItem :article="article" />
                    </div>
                    <a
                        @click="createClickEvent(article.id)"
                        :href="article.link"
                        target="_blank"
                        v-if="
                            article.status == 'published' &&
                            article.is_link == true
                        "
                        style="color: inherit !important"
                    >
                        <ArticleItem class="me-3" :article="article" />
                    </a>
                </span>
            </Flicking>
        </div>
        <div
            class="mb-5 container"
            v-if="homepageLinks && homepageLinks.length != 0"
        >
            <h3 class="mb-3">{{ t('pages.home.other') }}</h3>
            <ul class="list-group shadow-sm">
                <a
                    target="_blank"
                    class="list-group-item tap-react d-flex flex-row justify-content-between align-items-center"
                    :href="homepageLink.link"
                    :key="homepageLink.id"
                    v-for="homepageLink in homepageLinks"
                >
                    <div
                        style="font-weight: 500; gap: 0.5rem"
                        class="d-flex flex-row align-items-center"
                    >
                        <i class="fa fa-globe"></i>
                        {{ homepageLink.name }}
                    </div>
                    <i class="fa fa-chevron-right text-muted"></i>
                </a>
            </ul>
        </div>
    </div>
</template>

<script setup>
import AccountActivatedModal from './Modals/AccountActivatedModal';
import PasswordResetSuccessfulModal from './Modals/PasswordResetSuccessfulModal';
import { storeToRefs } from 'pinia';
import { useYachtsStore } from '@/stores/yachts-store';
import { useUserStore } from '@/stores/user-store';
import { useArticlesStore } from '@/stores/articles-store';
import { ref, computed, onMounted, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import Flicking from '@egjs/vue3-flicking';
import '@egjs/vue3-flicking/dist/flicking.css';
import YachtItem from '@/components/YachtItem.vue';
import ArticleItem from '@/components/ArticleItem.vue';
import SelectTimeModal from './Modals/SelectTimeModal.vue';
import router from '@/router';
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useBookingsStore } from '@/stores/bookings-store';

const moment = inject('moment');

const GLOBAL_VAR_SAFE_PADDING_TOP = inject('GLOBAL_VAR_SAFE_PADDING_TOP');
const GLOBAL_VAR_SAFE_PADDING_BOTTOM = inject('GLOBAL_VAR_SAFE_PADDING_BOTTOM');

const toggleAccountActivatedModal = ref(null);
const togglePasswordResetSuccessfulModal = ref(null);

const route = useRoute();
const { t } = useI18n();

const articlesStore = useArticlesStore();
articlesStore.fetchArticles();
const { articles } = storeToRefs(articlesStore);

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const yachtsStore = useYachtsStore();
yachtsStore.fetchYachts();
const { yachts } = storeToRefs(yachtsStore);

const bookingsStore = useBookingsStore();
bookingsStore.fetchBookings();
const { activeBookings } = storeToRefs(bookingsStore);

const homepageLinks = ref([]);
async function fetchHomepageLinks() {
    const res = await axios.get('homepage-links');
    homepageLinks.value = res.data;
}

onMounted(() => {
    if (route.query.dialog) {
        if (route.query.dialog == 'reset-password') {
            togglePasswordResetSuccessfulModal.value.click();
        } else if (route.query.dialog == 'account-activation') {
            toggleAccountActivatedModal.value.click();
        }
    }
    fetchHomepageLinks();
});

const subscribedYachts = computed(() => {
    return yachts.value.filter((yacht) => yacht.canBook);
});

const welcomeMessage = computed(() => {
    let currentHour = new Date().getHours();
    if (currentHour < 12) {
        return t('pages.home.goodMorning', {
            first_name: user.value.first_name,
        });
    } else if (currentHour < 18) {
        return t('pages.home.goodAfternoon', {
            first_name: user.value.first_name,
        });
    } else {
        return t('pages.home.goodEvening', {
            first_name: user.value.first_name,
        });
    }
});

/**
 * Featured yachts is sorted by UNSUBSCRIBED yachts.
 * Unsubscribed yachts by the subscriber will be shown first
 */
const featuredYachts = computed(() => {
    const hasSubscription = [];
    const noSubscription = [];
    yachts.value.forEach((yacht) => {
        if (yacht.accreditation === null) {
            noSubscription.push(yacht);
        } else {
            hasSubscription.push(yacht);
        }
    });
    return noSubscription.concat(hasSubscription);
});

function diff(booking) {
    const rawCheckIn = moment.utc(booking.start_timestamp);
    const rawCheckOut = moment.utc(booking.end_timestamp);
    const checkIn = moment(
        rawCheckIn.format('YYYY-MM-DD') + ' ' + rawCheckIn.format('HH:mm')
    );
    const checkOut = moment(
        rawCheckOut.format('YYYY-MM-DD') + ' ' + rawCheckOut.format('HH:mm')
    );
    const now = moment();

    const startDiff = checkIn.diff(now, 'hours', true);
    const endDiff = checkOut.diff(now, 'hours', true);
    const startsInText = checkIn.fromNow();
    const endsInText = checkOut.fromNow();

    if (endDiff <= 0) {
        // booking ended
        return {
            text: endsInText,
            status: t('pages.bookings.ended'),
            classes: 'bg-warning text-dark',
        };
    } else if (startDiff <= 0 && endDiff >= 0) {
        // booking active
        return {
            text: endsInText,
            status: t('pages.bookings.active'),
            classes: 'bg-success text-light',
        };
    }
    if (endDiff > 0 && startDiff > 0) {
        // booking hasn't started yet
        return {
            text: startsInText,
            status: t('pages.bookings.starts'),
            classes: 'bg-secondary text-light',
        };
    }
}

function getYacht(booking) {
    const yacht = yachts.value.find((yacht) => {
        return yacht.itself.id == booking.boat_id;
    });
    return yacht;
}

const createClickEvent = (articleId) => {
    axios.post(`articles/${articleId}/click`);
};

const goToArticle = (articleId) => {
    createClickEvent(articleId);
    router.push(`/articles/${articleId}`);
};
</script>

<style scoped>
.line::after {
    content: '';
    background-color: black;
    width: 1px;
    height: 50px;
    position: absolute;
    left: 0;
}

.line-container {
    position: relative;
}

.arrow-icon {
    font-size: 0.8rem;
}

.dot-icon {
    font-size: 0.5rem;
}

.bg-danger-hover:hover {
    opacity: 0.9;
}
</style>
