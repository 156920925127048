<template>
    <div
        class="modal fade"
        id="accountActivatedModal"
        tabindex="-1"
        style="z-index: 1000000"
    >
        <div class="modal-dialog modal-dialog-centered modal-sm">
            <div class="modal-content">
                <div class="modal-body text-center">
                    <div style="font-weight: 500">
                        {{ t('pages.home.accountActivated') }}
                    </div>
                    {{ t('pages.home.loggedIn') }}
                </div>
                <div class="border-top">
                    <a
                        data-bs-dismiss="modal"
                        href="#"
                        class="w-100"
                        style="font-weight: 600"
                    >
                        <div
                            class="px-2 py-2 text-center tap-react rounded-bottom"
                        >
                            {{ t('misc.modal.done') }}
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
</script>
