<template>
    <div class="article-item">
        <img
            class="img rounded mb-3"
            :src="GLOBAL_VAR_BACKEND_STORAGE_URL + article.main_image"
        />
        <h5 class="mb-3">{{ article.name }}</h5>
        <span class="text-muted">{{ publishedAt }}</span>
    </div>
</template>

<script setup>
import { ref, defineProps, inject } from 'vue';
import axios from 'axios';
const GLOBAL_VAR_BACKEND_STORAGE_URL = inject('GLOBAL_VAR_BACKEND_STORAGE_URL');
const moment = inject('moment');
const props = defineProps(['article', 'width']);
const article = ref(props.article);
const publishedAt = moment(article.value.created_at).fromNow();

axios.post(`articles/${article.value.id}/view`);
</script>

<style scoped>
.article-item:hover {
    transform: scale(0.95) !important;
}

.article-item {
    transition-property: transform;
    transition-duration: 0.5s;
    cursor: pointer;
}

/* For phones and smaller */
@media (max-width: 575.98px) {
    .article-item {
        width: 80vw;
    }
}

/* Medium devices and bigger (tablets, less than 992px) */
@media (min-width: 575.98px) {
    .article-item {
        width: 300px;
    }
}

.img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

h5 {
    word-wrap: break-word;
    margin-bottom: 0;
}
</style>
